@font-face {
  font-family: Gilroy-black;
  src: url("Gilroy-Black.3b082f53.eot");
  src: url("Gilroy-Black.3b082f53.eot#iefix") format("embedded-opentype"), url("Gilroy-Black.befe3715.woff") format("woff"), url("Gilroy-Black.14fca889.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: Gilroy-Bold;
  src: url("gilroy.6b26fd6d.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: Gilroy-Regular;
  src: url("Gilroy-Regular.0559e6a3.eot");
  src: url("Gilroy-Regular.0559e6a3.eot#iefix") format("embedded-opentype"), url("Gilroy-Regular.1eee673e.woff") format("woff"), url("Gilroy-Regular.7553081e.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: Gilroy-Medium;
  src: url("Gilroy-Medium.caaf85ff.ttf");
  font-display: swap;
}

@font-face {
  font-family: Gilroy-SemiBold;
  src: url("Gilroy-SemiBold.3dfb4469.ttf");
  font-display: swap;
}

@font-face {
  font-family: ObviouslyDemo-Wide;
  src: url("ObviouslyDemo-Wide.02d3705e.otf");
  font-display: swap;
}

@font-face {
  font-family: ClashDisplay-Variable;
  src: url("ClashDisplay-Variable.203a6647.ttf");
  font-display: swap;
}

.gilroy-medium, .Gilroy-medium, .Gilroy-Medium {
  font-family: Gilroy-Medium;
}

.gilroy-bold, .Gilroy-bold, .Gilroy-Bold {
  font-family: Gilroy-Bold;
}

.gilroy-semibold, .Gilroy-semibold, .Gilroy-semiBold {
  font-family: Gilroy-SemiBold;
}

.gilroy-regular, .Gilroy-regular, .Gilroy-Regular {
  font-family: Gilroy-Regular;
}

.gilroy-black, .Gilroy-black, .Gilroy-Black {
  font-family: Gilroy-Black;
}

.ObviouslyDemo {
  font-family: ObviouslyDemo-Wide;
}

.clash {
  font-family: ClashDisplay-Variable;
}

.heading_1 {
  font-family: Gilroy-black;
  font-size: 48px;
  line-height: 57.6px;
}

.heading_2 {
  font-family: Gilroy-semiBold;
  font-size: 40px;
  line-height: 48px;
}

.heading_3 {
  font-family: Gilroy-semiBold;
  font-size: 24px;
  line-height: 28.8px;
}

.heading_4 {
  font-family: Gilroy-Bold;
  font-size: 20px;
  line-height: 24px;
}

.accordion_faq ul li {
  font-family: Gilroy-Regular;
}

.heading_5 {
  font-family: Gilroy-Bold;
  line-height: 48px;
  font-size: 32px !important;
}

.heading_K_2 {
  font-family: Gilroy-Bold;
  font-size: 40px;
  line-height: 48px;
}

.heading_K_4 {
  font-family: Gilroy-Bold;
  font-size: 20px;
  line-height: 24px;
}

:root {
  --portfolio_new_dark: #202026;
  --portfolio_new_mute: #6d6d6d;
  --portfolio_new_grey: #777;
  --portfolio_new_voilet: #2942a7;
  --portfolio_new_border: #718096;
  --portfolio_new_light_grey: #f3f3f3;
  --portfolio_new_light_dark: #232323;
  --portfolio_new_voilet_dark: #2842a9;
}

.fs-22 {
  font-size: 22px;
}

.theme_new_voilet {
  color: var(--portfolio_new_voilet);
}

.newgrey_border {
  border-color: var(--portfolio_new_border);
}

.theme_new_light_dark {
  color: var(--portfolio_new_light_dark);
}

.theme_new_dark {
  color: var(--portfolio_new_dark);
}

.theme_new_grey {
  color: var(--portfolio_new_grey);
}

.theme_new_muted {
  color: var(--portfolio_new_mute);
}

.theme_new_voilet_dark {
  background: var(--portfolio_new_voilet_dark);
}

.heading_new_homepage {
  font-size: 45px;
  line-height: 62px;
}

.heading_new_K_1 {
  font-family: Gilroy-Bold;
  font-size: 42px;
  line-height: 48px;
}

.heading_new_K_3 {
  font-family: Gilroy-SemiBold;
  font-size: 35px;
  line-height: 48px;
}

.heading_new_K_5 {
  font-family: Gilroy-SemiBold;
  font-size: 22px;
  line-height: 32px;
}

.new_gradient-with-lernloft {
  background-color: #0000;
  background-image: url("new_bg_layer.5d1a7efb.png"), url("new_bg_layer.5d1a7efb.png"), linear-gradient(#2842a9d6 .17%, #2842a9 99.8%);
  background-position: 106% -65px, -8% 83px, 0 0;
  background-repeat: no-repeat, no-repeat;
  background-size: auto, auto, auto;
  background-attachment: scroll, scroll, scroll;
  background-origin: padding-box, padding-box, padding-box;
  background-clip: border-box, border-box, border-box;
}

.impact_sec {
  background: url("learnloft_impact_bg.aa49280d.png") center / cover no-repeat;
}

.icons_bg {
  background: url("design_bg.9e981ade.svg") center / cover no-repeat;
}

.user-review {
  background: var(--portfolio_new_light_grey);
}

.user-review:after {
  content: "";
  border-top: 19px solid #f7f7f7;
  border-left: 12px solid #0000;
  border-right: 12px solid #0000;
  width: 0;
  height: 0;
  position: absolute;
  bottom: -19px;
  left: 21px;
}

.icons_shadow {
  box-shadow: 0 20px 20px #2842a959;
}

.speedy_title {
  font-size: 24px;
}

.speedy_sub_title {
  font-size: 35px;
}

.gradient-with-image {
  background-blend-mode: overlay;
  background-color: #0000;
  background-image: linear-gradient(to right, #1d509e, #0d3676), url("pepsico_bg_layer.7b967b52.svg");
  background-position: 50% 70%;
  background-repeat: repeat, repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

.gradient-with-shl {
  background-blend-mode: overlay;
  background-color: #0000;
  background-image: linear-gradient(to right, #7ebb4b, #549320), url("pepsico_bg_layer.7b967b52.svg");
  background-position: 50% 70%;
  background-repeat: repeat, repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

.gradient-with-plately {
  background-blend-mode: overlay;
  background-color: #0000;
  background-image: linear-gradient(to right, #bd3565, #a32552), url("pepsico_bg_layer.7b967b52.svg");
  background-position: 50% 70%;
  background-repeat: repeat, repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

.gradient-with-clinic {
  background-blend-mode: overlay;
  background-color: #0000;
  background-image: linear-gradient(to right, #0166e5, #2825bf), url("pepsico_bg_layer.7b967b52.svg");
  background-position: 50% 70%;
  background-repeat: repeat, repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

.gradient-with-myysports {
  background-blend-mode: overlay;
  background-color: #0000;
  background-image: linear-gradient(to right, #543fd6, #2825bf), url("pepsico_bg_layer.7b967b52.svg");
  background-position: 50% 70%;
  background-repeat: repeat, repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

.gradient-with-quixy {
  background-blend-mode: overlay;
  background-color: #0000;
  background-image: linear-gradient(to right, #0565ff, #0565ff), url("pepsico_bg_layer.7b967b52.svg");
  background-position: 50% 70%;
  background-repeat: repeat, repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

.gradient-with-jaisiri {
  background-blend-mode: overlay;
  background-color: #0000;
  background-image: linear-gradient(to right, #54e6b6, #33c192, #139c6f), url("pepsico_bg_layer.7b967b52.svg");
  background-position: 50% 70%;
  background-repeat: repeat, repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

.text-K-outline {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-color: #4f4f4f;
  -webkit-text-stroke-width: .012em;
}

.myysports_last_banner_sec .text-K-outline {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-color: #fff;
  -webkit-text-stroke-width: .012em;
}

.free_counsult_bg {
  background: #f9f9ff;
}

.clinic_last_banner_sec {
  background-color: #0000;
  background-image: url("what_impact_clinic_large.518c15d6.webp"), linear-gradient(to right, #0a1222, #0a1222);
  background-position: 100% 100%;
  background-repeat: no-repeat, no-repeat;
  background-size: 50%, 100%;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

.myysports_last_banner_sec {
  background-color: #0000;
  background-image: url("myysports_what_impact_web.eab7b312.webp"), linear-gradient(to right, #141b2b, #141b2b);
  background-position: 98% -3%;
  background-repeat: no-repeat, no-repeat;
  background-size: 200%, 100%;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

.quixy_watermarkText {
  font-family: Gilroy-bold;
  font-size: 152px;
}

.box-quixy {
  background: linear-gradient(90deg, #fff 68% 68%) 100% 0 / 406px no-repeat;
}

.result_bg {
  background-blend-mode: overlay;
  background-color: #0000;
  background-image: linear-gradient(to right, #fff, #fff), url("quixy_result_bg.88b72ffd.webp");
  background-position: 2% 70%;
  background-repeat: repeat, repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

.gradient-btn {
  color: #fff;
  background: linear-gradient(91.17deg, #2842a9d6 .17%, #2842a9 99.8%);
}

.body_1 {
  font-family: Gilroy-Regular;
  font-size: 20px;
  line-height: 30px;
}

.body_2 {
  font-family: Gilroy-Regular;
  font-size: 16px;
  line-height: 24px;
}

.sub_heading_1 {
  font-family: Gilroy-Medium;
  font-size: 18px;
  line-height: 26px;
}

.sub_heading_2 {
  font-size: 17px;
  line-height: 23px;
}

.sub_heading_3 {
  font-family: Gilroy-Medium;
  font-size: 16px;
  line-height: 23px;
}

.Gilroy-Regular {
  font-family: Gilroy-Regular;
}

.Gilroy-semiBold {
  font-family: Gilroy-semiBold;
}

.text-color-black, .text-color-gray {
  color: #050505;
}

.text-color-body {
  color: #4f4f50;
}

.gradiantText {
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  background: linear-gradient(90deg, #5c3eb3 0%, #c142c3 105.44%) text;
}

.headingFive {
  color: #050505;
  font-family: Gilroy-semiBold;
  font-size: 20px;
  line-height: 30px;
}

.miniPara {
  font-family: Gilroy-Regular;
  font-size: 14px;
  line-height: 20px;
}

.footerLinks {
  color: #050505;
  opacity: .8;
  font-family: Gilroy-semiBold;
  font-size: 13px;
  line-height: 30px;
}

.footerLinks a:hover {
  text-underline-offset: 8px;
  color: #5c3eb3;
  text-decoration: underline;
}

.borderB {
  border-bottom: 1px solid #5c3eb3;
}

.wrapper {
  position: relative;
}

.wrapper .center-line {
  background: #5c3eb3;
  width: 4px;
  height: 98%;
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.wrapper .row {
  display: flex;
}

.wrapper .row-1 {
  justify-content: flex-start;
}

.wrapper .row-2 {
  justify-content: flex-end;
}

.wrapper .row section {
  border-radius: 5px;
  width: calc(50% - 40px);
  padding-top: 1rem;
  position: relative;
}

.row section .icon, .center-line .scroll-icon {
  text-align: center;
  background: #c6b6f5;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  display: grid;
  position: absolute;
}

.row-1 section .icon {
  top: 15px;
  right: -52px;
}

.row-1 section {
  text-align: right;
}

.row-2 section .icon {
  top: 15px;
  left: -52px;
}

.small_circle {
  background-color: #5c3eb3;
  border-radius: 50%;
  width: 15px;
  height: 15px;
}

.tech-n-20 {
  top: 0;
}

@media (width <= 768px) {
  .heading_1 {
    font-size: 32px;
    line-height: 36px;
  }

  .heading_2 {
    font-size: 24px;
    line-height: 28.8px;
  }

  .heading_3 {
    font-size: 18px;
    line-height: 24px;
  }

  .heading_4 {
    font-size: 14px;
    line-height: 23.4px;
  }

  h1.heading_K_2 {
    font-family: Gilroy-Bold;
    font-size: 32px;
    line-height: 45px;
  }

  .heading_K_2 {
    font-family: Gilroy-Bold;
    font-size: 20px;
    line-height: 38px;
  }

  .heading_K_4 {
    font-family: Gilroy-Bold;
    font-size: 16px;
    line-height: 23.4px;
  }

  .heading_5 {
    font-family: Gilroy-Bold;
    line-height: 31px;
    font-size: 24px !important;
  }

  .coke_data .heading_5 {
    font-size: 20px !important;
  }

  .plately_last_banner_sec {
    background-image: url("what_impact_plately.30da5848.webp"), linear-gradient(90deg, #0a1222 100% 100%);
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-size: 100%, 100%;
  }

  .tech-n-20 {
    top: -30vw;
  }

  #parant_relative {
    margin-top: 5%;
  }

  .body_1 {
    font-size: 16px;
    line-height: 24px;
  }

  .body_2 {
    font-size: 14px;
    line-height: 24px;
  }

  .sub_heading_1 {
    font-size: 18px;
    line-height: 23.4px;
  }

  .sub_heading_2 {
    font-size: 16px;
    line-height: 20.8px;
  }

  .sub_heading_3 {
    font-size: 14px;
    line-height: 18.2px;
  }

  .wrapper .center-line {
    left: -30px;
  }

  .wrapper .row section {
    width: 100%;
  }

  .row-1 section:before {
    left: -7px;
  }

  .row-1 section .icon {
    left: -43px;
  }

  .row-2 section .icon {
    top: 15px;
    left: -43px;
  }

  .row section .icon, .center-line .scroll-icon {
    width: 25px;
    height: 25px;
  }

  .row-1 section {
    text-align: left;
  }

  .wrapper .row {
    margin: 10px 0;
  }
}

.thanks_card:before {
  content: "";
  background: #ff7575;
  width: 99%;
  height: 2px;
  position: absolute;
  top: 20px;
  right: -58.2%;
}

.thanks_card:last-child:before {
  display: none;
}

@media (width <= 1023px) {
  .thanks_card:before {
    display: none;
  }

  .thanks_card:after {
    content: "";
    background: #ff7575;
    width: 2px;
    height: 99%;
    position: absolute;
    top: 42px;
    left: 21px;
  }

  .tech-n-20 {
    top: -15vw;
  }
}

@media (width >= 1025px) and (width <= 1279px) {
  .thanks_card:before {
    content: "";
    background: #ff7575;
    width: 99%;
    height: 2px;
    position: absolute;
    top: 20px;
    right: -56.8%;
  }
}

@media (width >= 1280px) and (width <= 1440px) {
  .thanks_card:before {
    content: "";
    background: #ff7575;
    width: 99%;
    height: 2px;
    position: absolute;
    top: 20px;
    right: -55.8%;
  }
}

@media (width >= 1441px) {
  .thanks_card:before {
    content: "";
    background: #ff7575;
    width: 99%;
    height: 2px;
    position: absolute;
    top: 20px;
    right: -52.8%;
  }
}

@media (width >= 319px) and (width <= 639px) {
  .heading_new_K_1 {
    font-size: 28px;
    line-height: 38px;
  }

  .speedy_sub_title {
    font-size: 20px;
  }

  .new_gradient-with-lernloft {
    background-color: #0000;
    background-image: url("new_bg_layer.5d1a7efb.png"), url("new_bg_layer.5d1a7efb.png"), linear-gradient(#2842a9d6 .17%, #2842a9 99.8%);
    background-position: 134% -65px, -30% 206px, 0 0;
    background-repeat: no-repeat, no-repeat;
    background-size: auto, auto, auto;
    background-attachment: scroll, scroll, scroll;
    background-origin: padding-box, padding-box, padding-box;
    background-clip: border-box, border-box, border-box;
  }

  .heading_new_K_3 {
    font-family: Gilroy-SemiBold;
    font-size: 24px;
    line-height: 32px;
  }

  .fs-22 {
    font-size: 18px;
  }

  .heading_new_homepage, .android_heading {
    font-size: 24px;
    line-height: 33.6px;
  }

  .tech-n-20 {
    top: -40vw;
  }

  #parant_relative {
    margin-top: 25%;
  }

  .headingFive {
    font-size: 14px;
  }
}

@media (width >= 768px) {
  .AMCAT_test {
    background: url("shl_mid_second_banner.5f982617.webp") center / cover no-repeat;
  }

  .shl_second_banner {
    background: url("shl_mid_without_content_banner.c8db8b67.webp") center / cover no-repeat;
  }

  .shl_second_banner .heading_K_4 {
    line-height: 26px;
  }

  .plately_last_banner_sec {
    background-image: url("what_impact_plately_web.6716c514.webp"), linear-gradient(90deg, #0a1222 100% 100%);
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-size: 80%, 100%;
  }

  .myysports_last_banner_sec {
    background-color: #0000;
    background-image: url("myysports_what_impact_web.eab7b312.webp"), linear-gradient(to right, #141b2b, #141b2b);
    background-position: 98% 11%;
    background-repeat: no-repeat, no-repeat;
    background-size: 160%, 100%;
    background-attachment: scroll, scroll;
    background-origin: padding-box, padding-box;
    background-clip: border-box, border-box;
  }
}
/*# sourceMappingURL=about-us.ef3ce5c5.css.map */
